<template>
  <div>
    <section v-if="cargar_producto" class="d-flex justify-center mt-15">
      <v-progress-circular indeterminate color="primary" />
    </section>
    <v-row v-else>
      <v-col cols="12">
        <p class="text-h6 text--secondary">Catálogo de compras</p>
      </v-col>
      <v-col cols="12" md="6" xl="3">
        <ProductCarouselComponent :imagenes="obs_imagenes" />
      </v-col>
      <v-col cols="12" md="6" xl="9">
        <v-row>
          <v-col md="12" lg="6">
            <v-row>
              <v-col cols="12">
                <p class="text-h6 text--secondary">
                  {{ productoSeleccionado.nombre }}
                </p>
              </v-col>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text pt-5"
              >
                <p>{{ productoSeleccionado.obs_descripcion }}</p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">OBS:</p>
              </v-col>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p>
                  {{ productoSeleccionado.codigo }} -
                  {{ productoSeleccionado.obs }}
                </p>
              </v-col>
              <v-col
                cols="12"
                lg="8"
                xl="6"
                class="caracteristica-producto-titulo pt-5"
              >
                <v-select
                  v-model="productoSeleccionado.id"
                  item-text="nombre"
                  item-value="id"
                  :items="productoSeleccionado.presentaciones"
                  outlined
                  label="Presentación"
                  @change="getCurrentPresentation"
                />
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">Unidad de venta:</p>
              </v-col>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p>{{ productoSeleccionado.nombre_presentacion }}</p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">Detalle:</p>
              </v-col>
              <v-col
                cols="5"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p
                  v-for="(detail, index) in productoSeleccionado.etiquetas"
                  :key="index"
                >
                  {{ detail.nombre }}
                </p>
              </v-col>
              <v-col cols="12" class="caracteristica-producto-titulo pt-5">
                <p class="text-caption">Precio:</p>
              </v-col>
              <v-col
                cols="12"
                class="caracteristica-producto-subtitulo primary--text"
              >
                <p>
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(productoSeleccionado.precio_unitario)
                  }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="12" lg="6">
            <p>Valoraciones:</p>
            <v-rating
              readonly
              :value="ratingProvider"
              half-increments
              background-color="secondary"
              color="secondary"
              size="48"
            />
            <v-col cols="8">
              <!-- <v-text-field value="50" outlined label="Cantidad a solicitar" /> -->
              <v-row>
                <v-col cols="6">
                  <v-btn @click="solicitar()" block color="secondary">
                    Solicitar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-btn outlined color="secondary" @click="$router.back()" class="mt-15">
      Regresar
    </v-btn>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ProductCarouselComponent from "./ProductCarouselComponent.vue";
export default {
  name: "CatalogoCompraProductosComponent",
  components: {
    ProductCarouselComponent,
  },
  data: () => ({
    obs_imagenes: [],
    ratingProvider: 0,
    id_presentacion_seleccionada: null,
  }),
  computed: {
    ...mapState("catalogo", ["productoSeleccionado", "cargar_producto"]),
    ...mapState("agregarInsumo", ["id_solicitud"]),
  },
  methods: {
    ...mapMutations("catalogo", ["idProductoSeleccionado"]),
    ...mapActions("catalogo", ["obtenerProducto"]),
    solicitar() {
      this.$router.push({
        name: "agregar-insumo-detalles",
        params: { tipo: 2, idSolicitud: this.id_solicitud },
      });
      this.idProductoSeleccionado(this.id_presentacion_seleccionada);
    },
    getPresentationImages() {
      const obs_foto_ids = this.productoSeleccionado.obs_fotos;
      let promises = [];
      let images = [];
      this.obs_imagenes = [];
      for (let i = 0; i < obs_foto_ids.length; i++) {
        promises.push(
          this.services.AgreementMarco.getImagenesPresentacion(
            obs_foto_ids[i].id
          ).then((response) => {
            let files = response?.data;
            const reader = new FileReader();
            reader.onload = (files) => {
              images.push({
                id: i + 1,
                url: files.target.result,
              });
            };
            reader.readAsDataURL(files);
          })
        );
      }
      Promise.all(promises).then(() => {
        setTimeout(() => {
          this.obs_imagenes = images;
        }, 200);
      });
    },
    async fetchCalificacion() {
      const idProveedor =
        this.productoSeleccionado?.obs_proveedor?.id_proveedor;
      const { data } = await this.services.Proveedores.getCalificacionProveedor(
        idProveedor
      );
      if (data) {
        this.ratingProvider = Number.parseFloat(data?.calificacion);
      }
    },
    async getCurrentPresentation(id) {
      await this.obtenerProducto(id);
      this.fetchCalificacion();
      this.getPresentationImages();
      this.id_presentacion_seleccionada = id;
    },
  },
  created() {
    this.getCurrentPresentation(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
.caracteristica-producto-titulo,
.caracteristica-producto-subtitulo {
  padding: 0px 12px 0px 12px;
  margin: 0px;
  p {
    margin: 0px 0px 5px 0px;
  }
}
</style>
